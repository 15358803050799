
/* eslint-disable */
import { request } from '@/ts/api';
import { defineComponent, onMounted, Ref, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import Popup from '@/components/Popup.vue';

export default defineComponent({
  components: {
    Popup
  },
  setup() {
    //#region Data
    const router = useRouter();

    const settingsJson: Ref<any> = ref({});
    const folderName = ref('');
    const songFiles: Ref<Array<any>> = ref([]);
    const addErrorMessage = ref('');
    const loading = ref(false);
    const loadingText = ref('');
    const fileInput: Ref<any> = ref(null);
    const songsList = ref([]);
    const songToDelete = ref('');

    const showDeletePopup = ref(false);
    //#endregion

    //#region Lifecycle
    onMounted(() => {
      document.title = 'Slideshow Songs';

      const settings = localStorage.getItem('settings');
      if (settings) {
        const settingsObject = JSON.parse(settings);
        folderName.value = settingsObject?.folder;
        settingsJson.value = settingsObject;

        getSongsList();
      } else {
        router.push('/');
      }
    })
    //#endregion
    
    //#region Methods
    const songChanged = (e: any) => {
      songFiles.value = Array.from(e.target.files);
    }
    const addClicked = async () => {
      addErrorMessage.value = '';

      if (!folderName.value) {
        addErrorMessage.value = 'Please enter your folder name';
        return;
      }

      if (songFiles.value.length == 0) {
        addErrorMessage.value = 'Please select at least 1 song to upload';
        return;
      }
      
      const formData = new FormData();
      formData.append('folder', folderName.value);
      songFiles.value.forEach((f:any) => formData.append('song', f));
      
      loading.value = true;
      let loaded = 0;
      await request.post('/AddSong', formData, {
        onUploadProgress: (e) => {
          loadingText.value = `Uploading ${Math.ceil((e.progress || 0) * 100)}%`
        },
        onDownloadProgress: (e) => {
          loadingText.value = `${e.event.target.response.substring(loaded, e.loaded)}`;
          loaded = e.loaded;
        }
      })
      loadingText.value = 'Upload Completed!'

      setTimeout(() => {
        loading.value = false;
        fileInput.value.value = null;
        songFiles.value = [];

        getSongsList();
      }, 3000);
    }

    const getSongsList = async () => {
      if (!folderName.value) {
        songsList.value = [];
        document.title = 'Slideshow Songs';
      } else {
        try {
          songsList.value = (await request.get(`/GetSongsList?folder=${folderName.value}`)).data;
        } catch (error) {
          songsList.value = [];
        }
      }
    }
    
    const deleteSongClicked = (song: string) => {
      showDeletePopup.value = true;
      songToDelete.value = song;
    }

    const deletePopupButtonClicked = async (text: string) => {
      showDeletePopup.value = false;
      switch (text) {
        case 'Delete':
          try {
            await request.delete(`/DeleteSong`, {
              data: {
                folder: settingsJson.value.folder,
                filename: songToDelete.value
              }
            });
            songsList.value = [];
            getSongsList();
          } catch {
            console.log('error deleting song');
          }
          break;
      }
    }
    //#endregion

    return {
      //#region Data
      settingsJson,
      folderName,
      addErrorMessage,
      loading,
      loadingText,
      fileInput,
      songsList,

      showDeletePopup,
      //#endregion
      
      //#region Methods
      songChanged,
      addClicked,

      deleteSongClicked,

      deletePopupButtonClicked,
      //#endregion
    }
  },
})
